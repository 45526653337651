import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import Loader from '../loader/Loader';
import { FilterOption } from '../custom-input/FilterDropdown';
import ToggleSwitch from '../custom-input/ToggleSwitch';
import FilterBy from '../filter-by/FilterBy';
import SearchBy from '../search-by/SearchBy';
import FilterByModal from '../filter-by/FilterByModal';
import { FilterSVG, ClearFilterSVG, PlusCircleSvg, CancelSVG, DownloadSVG } from '../svgs';
import { dateFilter } from '../../utils/dateHandler';
import { dateFormatMMDDYYYY, formatDateToShortMonthDayYear } from '../../utils/commonUtils';
import { Account, PagedResponse, StandardParamsToODataParams } from '../../types/interfaces';
import { TransactionTypeEnum } from '../../types/enums';
import { getAccounts } from '../../api/accountApi';
import { downloadInventoryTransactionReport } from '../../api/transactionsApi';
import { showToast } from '../../services/toast.service';
import { TRANSACTION_MANAGEMENT_PATHS } from '../../constants/routePaths';
import { LABEL_CONSTANTS, TRANSACTION_CONSTANTS } from '../../constants/common';
import { toastMessages } from '../../constants/errorMessages';
import './transactionsList.scss';

export interface TransactionFilterState {
  type: string;
  status: string;
  resolution: string;
  createdDatetime: { startDate: Date | null; endDate: Date | null };
  dateWasModified: boolean;
  searches: { criteria: FilterOption<TransactionFilterOption>; query: string }[];
}

interface TransactionsListProps {
  searchCriteriaOptions: FilterOption<TransactionFilterOption>[];
  header: string;
  setFilter: (filters: string) => void;
  filterPreset?: TransactionFilterState;
  setFilterPreset?: (filters: TransactionFilterState) => void;
  children?: React.ReactNode | React.ReactNode[];
  isOData?: boolean;
  includeAdminFilters?: boolean;
  showBulkTransactions?: boolean;
  onToggleShowBulkTransactions?: () => void;
  isTransactionHistoryPage?: boolean;
  accountOwner?: Account;
}

export interface TransactionFilterOption {
  parameter: string;
  isNumber?: boolean;
}

const defaultFilterPreset = {
  type: '',
  status: '',
  resolution: '',
  createdDatetime: { startDate: null, endDate: null },
  dateWasModified: false,
  searches: [],
};

const TransactionsList: FC<TransactionsListProps> = ({
  searchCriteriaOptions,
  header,
  setFilter,
  setFilterPreset = (filters): void => {},
  filterPreset = defaultFilterPreset,
  isOData = false,
  includeAdminFilters = false,
  children,
  showBulkTransactions,
  onToggleShowBulkTransactions,
  isTransactionHistoryPage,
  accountOwner,
}): JSX.Element => {
  const navigate = useNavigate();
  const user = useAppSelector(state => state.user);

  const [filterState, setFilterState] = useState<TransactionFilterState>(filterPreset);
  const [isOpenFilterByModal, setIsOpenFilterByModal] = useState<boolean>(false);
  const [onToggle, setOnToggle] = useState<boolean>(false);
  const [selectedFilterString, setSelectedFilterString] = useState<string>();
  const [downloadingReport, setDownloadingReport] = useState<boolean>(true);
  const [account, setAccount] = useState<Account>();
  const [resetSearchCriteria, setResetSearchCriteria] = useState<number>(0);

  const updateFilters = useCallback(async () => {
    let filters: string[] = [];

    if (!includeAdminFilters) {
      filters = [
        `transactionType ne '${TransactionTypeEnum.CheckIn}' and transactionType ne '${TransactionTypeEnum.CheckOut}' and transactionType ne '${TransactionTypeEnum.Adjust}' and transactionType ne '${TransactionTypeEnum.Move}'`,
      ];
    }

    if (filterState.type) {
      filters = [...filters, `transactionType eq '${filterState.type}'`];
    }

    if (filterState.status) {
      filters = [...filters, `status eq '${filterState.status}'`];
    }

    if (filterState.resolution) {
      filters = [...filters, `resolution eq '${filterState.resolution}'`];
    }

    if (filterState.createdDatetime.startDate && filterState.createdDatetime.endDate && filterState.dateWasModified) {
      dateFilter(filterState.dateWasModified, filterState.createdDatetime);
      filters = [...filters, dateFilter(filterState.dateWasModified, filterState.createdDatetime)];
    }

    for (const search of filterState.searches) {
      const minSearchLength = 2;
      if (search.query.length > 0) {
        if (search.criteria.value.parameter === 'accountId' && search.query.length >= minSearchLength) {
          try {
            const maxResults = 5;
            const { data: accountsResponse } = await getAccounts(undefined, {
              filter:
                'contactEmail like ' +
                search.query +
                '||name like ' +
                search.query +
                '||contactFirstName like ' +
                search.query +
                '||contactLastName like ' +
                search.query,
              limit: maxResults,
            });
            const accounts: Account[] = (accountsResponse as PagedResponse<Account>).result;
            if (accounts.length === 0) {
              setFilter('false');
              return;
            } else if (accounts.length === 1) {
              setAccount(accounts[0]);
            } else {
              setAccount(undefined);
            }

            const accountFilters = `(${accounts.map(acc => `accountId eq ${acc.accountId}`).join(' or ')})`;
            filters = [...filters, accountFilters];
          } catch (error) {
            setFilter('false');
            return;
          }
        } else {
          if (search.criteria.value.isNumber === true) {
            if ((+search.query).toString() === 'NaN') {
              setFilter('false');
              return;
            } else {
              if (showBulkTransactions && search.criteria.name != TRANSACTION_CONSTANTS.TRANSACTION_ID) {
                filters = [
                  ...filters,
                  `InventoryTransactions/any(it: it/${search.criteria.value.parameter} eq ${+search.query})`,
                ];
              } else {
                filters = [...filters, `${search.criteria.value.parameter} eq ${+search.query}`];
              }
            }
          } else if (search.query.length >= minSearchLength) {
            if (isOData) {
              if (showBulkTransactions) {
                filters = [
                  ...filters,
                  `InventoryTransactions/any(it:contains(it/${search.criteria.value.parameter}, '${search.query}'))`,
                ];
              } else {
                filters = [...filters, `contains(${search.criteria.value.parameter}, '${search.query}')`];
              }
            } else {
              filters = [...filters, `${search.criteria.value.parameter} like ${search.query}`];
            }
          }
        }
      }
    }

    const rawFilterString = filters.length > 1 ? `(${filters.join(') and (')})` : filters.join('');
    const filterString = isOData ? rawFilterString : rawFilterString.replace(/'/g, '');
    setSelectedFilterString(filterString);
    setFilter(filterString);
  }, [filterState]);

  useEffect(() => {
    if (accountOwner) {
      setAccount(accountOwner);
    }
  }, [accountOwner]);

  useEffect(() => {
    updateFilters();
    setFilterPreset(filterState);
    if (onToggle) {
      onToggleShowBulkTransactions != undefined && onToggleShowBulkTransactions();
      setOnToggle(false);
    }
  }, [filterState]);

  const onToggleTransactions = () => {
    setFilterState({ ...filterState, type: '', resolution: '', searches: [] });
    setOnToggle(true);
  };

  const handleResetFilters = () => {
    setFilterState(defaultFilterPreset);
    setResetSearchCriteria(resetSearchCriteria + 1);
  };

  const handleRemoveFilter = (index: number) => {
    const updatedSearch = [...filterState.searches];
    const removedFilter = updatedSearch.splice(index, 1)[0];

    if (removedFilter.criteria.name === 'Account / Owner') {
      setAccount(undefined);
    }

    setFilterState({ ...filterState, searches: updatedSearch });
  };

  const handleDownloadClick = () => {
    if (!filterState.createdDatetime.startDate || !filterState.createdDatetime.endDate) {
      showToast.warning(toastMessages.SELECT_DATE_RANGE);
    } else {
      handleDownloadReport();
    }
  };

  const getODataParams = () => {
    return StandardParamsToODataParams({
      filter: isTransactionHistoryPage
        ? selectedFilterString
        : `${
            !showBulkTransactions
              ? selectedFilterString
                ? selectedFilterString.concat(' and ', 'BulkOrderId eq null')
                : 'BulkOrderId eq null'
              : selectedFilterString
          }`,
      sort: '^' + 'CreatedDatetime',
    });
  };

  const generateReportPayload = () => {
    const startDate = formatDateToShortMonthDayYear(filterState.createdDatetime.startDate);
    const endDate = formatDateToShortMonthDayYear(filterState.createdDatetime.endDate);

    const searchFilters = {
      'Account Name :': account
        ? account.name
        : filterState.searches.find(search => search.criteria.name === 'Account / Owner')?.query,
      'Transaction Id :': filterState.searches.find(search => search.criteria.name === 'Transaction ID')?.query,
      'Animal Code :': filterState.searches.find(search => search.criteria.name === 'Animal Code')?.query,
      'Animal Name :': filterState.searches.find(search => search.criteria.name === 'Animal Name')?.query,
      'Lot Number :': filterState.searches.find(search => search.criteria.name === 'Lot Number')?.query,
      'Transaction Type :': filterState.type.length > 0 ? filterState.type : undefined,
      'Transaction Status :': filterState.status.length > 0 ? filterState.status : undefined,
      'Resolution :': filterState.resolution.length > 0 ? filterState.resolution : undefined,
      'Report Period :': `${startDate} - ${endDate}`,
    };

    const adminInfo = includeAdminFilters
      ? { 'Created By :': `${user.firstName} ${user.lastName}`, 'User Name :': user.username }
      : {};

    return { ...adminInfo, ...searchFilters };
  };

  const setReportName = (link: HTMLAnchorElement) => {
    const prefix = 'GeneticTxns';
    const currentDate = dateFormatMMDDYYYY(new Date());
    const fileName = account ? `${prefix}_${account.name}_${currentDate}.xlsx` : `${prefix}_${currentDate}.xlsx`;
    link.setAttribute('download', fileName);
  };

  const handleDownloadReport = async () => {
    if (!downloadingReport) return;

    try {
      setDownloadingReport(false);

      const response = await downloadInventoryTransactionReport(generateReportPayload(), getODataParams());
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      setReportName(link);
      document.body.appendChild(link);
      link.click();
    } catch (error: any) {
      showToast.error(toastMessages.FAILED_TO_DOWNLOAD_REPORT);
    } finally {
      setDownloadingReport(true);
    }
  };

  return (
    <div className="transaction-list">
      <div className="header">
        <h2 className="transaction-header">{header}</h2>
        <div className="search-stack">
          <div className="search">
            <SearchBy
              searches={filterState.searches}
              setSearches={searches => setFilterState({ ...filterState, searches: searches })}
              searchCriteriaOptions={searchCriteriaOptions}
              resetSearchCriteria={resetSearchCriteria}
            />
          </div>
        </div>
      </div>

      <hr className="header-hr" />

      <div className="selected-filters-summary">
        {filterState.searches.map((item, index) => (
          <div key={index} className="filter-summary">
            <span>
              {item.criteria.name}: <strong>{item.query}</strong>
            </span>
            <button onClick={() => handleRemoveFilter(index)} className="remove-filter-btn">
              <CancelSVG />
            </button>
          </div>
        ))}
      </div>

      <div className="filter-row">
        <FilterBy
          type={filterState.type}
          setType={type => setFilterState({ ...filterState, type: type })}
          status={filterState.status}
          setStatus={status => setFilterState({ ...filterState, status: status })}
          resolution={filterState.resolution}
          setResolution={resolution => setFilterState({ ...filterState, resolution: resolution })}
          dateRange={filterState.createdDatetime}
          setDateRange={e => setFilterState({ ...filterState, createdDatetime: e, dateWasModified: true })}
          showBulkTransactions={showBulkTransactions}
          onToggleShowBulkTransactions={onToggleTransactions}
          includeAdminFilters={includeAdminFilters}
          resetFilters={handleResetFilters}
          onDownloadReportClick={handleDownloadReport}
        />
      </div>

      <div className="xs-filter-row">
        <>
          <div className="create-transaction">
            {includeAdminFilters && (
              <button
                className="button outlined"
                onClick={() => navigate(TRANSACTION_MANAGEMENT_PATHS.CREATE_CLIENT_TRANSACTION)}>
                <PlusCircleSvg />
                &nbsp;&nbsp;Create&nbsp;Client&nbsp;Transaction
              </button>
            )}
            {!showBulkTransactions && (
              <button className="button outlined download-report" onClick={handleDownloadClick}>
                <DownloadSVG />
                &nbsp;{LABEL_CONSTANTS.DOWNLOAD}
              </button>
            )}
          </div>
          {includeAdminFilters && onToggleShowBulkTransactions != undefined && showBulkTransactions != undefined && (
            <ToggleSwitch label="Bulk Transactions" checked={showBulkTransactions} onToggle={onToggleTransactions} />
          )}
          <hr className="header-hr" />
        </>

        <div className="filter-icon-row">
          <p onClick={() => setIsOpenFilterByModal(true)}>
            <FilterSVG />
            <label>{LABEL_CONSTANTS.FILTER_BY}</label>
          </p>
          <div className="reset-filters-button flex-right" onClick={handleResetFilters}>
            <ClearFilterSVG />
            <label>{LABEL_CONSTANTS.RESET_FILTERS}</label>
          </div>
        </div>

        {isOpenFilterByModal && (
          <FilterByModal
            isOpen={isOpenFilterByModal}
            onClose={() => setIsOpenFilterByModal(false)}
            filterState={filterState}
            setFilterState={setFilterState}
            includeAdminFilters={includeAdminFilters}
            defaultFilterPreset={defaultFilterPreset}
          />
        )}
      </div>
      {!downloadingReport && <Loader loaderSize="small" simple appLoader />}
      {children}
    </div>
  );
};

export default TransactionsList;
