import Modal from '../modal/Modal';
import { ACCOUNT_CONSTANTS, BUTTON_CONSTANTS } from '../../constants/common';
import { Account } from '../../types/interfaces/account.interfaces';
import './billingEnableModal.scss';

type BillingEnableModalProps = {
  isOpen: boolean;
  width?: number;
  onClose: () => void;
  onConfirm: () => void;
  account?: Account;
};

const BillingEnableModal = ({ isOpen, width = 600, onClose, onConfirm, account }: BillingEnableModalProps) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} width={width} ignoreBackdrop>
        <div className="enable-account-modal">
          <div className="header">
            <h4>{ACCOUNT_CONSTANTS.ENABLE_BILLING}</h4>
          </div>
          <div className="body">
            <p>
              <span className="enable-billing-message">
                {ACCOUNT_CONSTANTS.ENABLE_BILLING_SUBHEADER} <span className="account-name">{account?.name}</span>?
              </span>
            </p>
          </div>
          <div className="footer">
            <button className="button blue inverted small" onClick={onClose}>
              {BUTTON_CONSTANTS.CANCEL}
            </button>
            <button className="small green button" onClick={onConfirm}>
              {BUTTON_CONSTANTS.YES}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BillingEnableModal;
