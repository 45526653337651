import { useEffect, useState } from 'react';
import DateInput from '../custom-input/DateInput';
import Modal from '../modal/Modal';
import AlertSVG from '../svgs/Alert.svg';
import { ACCOUNT_CONSTANTS, BUTTON_CONSTANTS, LABEL_CONSTANTS, VALIDATION_ALERT_CONSTANTS } from '../../constants/common';
import { Account } from '../../types/interfaces/account.interfaces';
import './billingDisableModal.scss';

type BillingDisableModalProps = {
  isOpen: boolean;
  width?: number;
  onClose: () => void;
  onConfirm: (data: { startDate?: Date; endDate?: Date | null | undefined; notes?: string }) => void;
  account?: Account;
};

const BillingDisableModal = ({ isOpen, width = 750, onClose, onConfirm, account }: BillingDisableModalProps) => {
  const [permanentlyDisable, setPermanentlyDisable] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [notes, setNotes] = useState<string>('');

  useEffect(() => {
    if (isOpen && account?.billingOffStartMonth) {
      const isPermanent = !account.billingOffEndMonth;
      setPermanentlyDisable(isPermanent);
      setStartDate(account?.billingOffStartMonth ? new Date(account.billingOffStartMonth) : undefined);
      setEndDate(account?.billingOffEndMonth ? new Date(account.billingOffEndMonth) : undefined);
      setNotes(account?.notes || '');
    }
  }, [account]);

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      setStartDate(firstDayOfMonth);
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      setEndDate(lastDayOfMonth);
    } else {
      setEndDate(undefined);
    }
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleConfirm = () => {
    if (permanentlyDisable) {
      const firstDayOfCurrentMonth = new Date();
      firstDayOfCurrentMonth.setDate(1);
      onConfirm({ startDate: firstDayOfCurrentMonth, endDate: null, notes });
    } else {
      onConfirm({ startDate, endDate, notes });
    }
    resetForm();
  };

  const resetForm = () => {
    setPermanentlyDisable(false);
    setStartDate(undefined);
    setEndDate(undefined);
    setNotes('');
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} width={width} ignoreBackdrop>
        <div className="disable-account-modal">
          <div className="header">
            <h4>{ACCOUNT_CONSTANTS.DISABLE_BILLING}</h4>
          </div>
          <div className="body">
            <div className="form-row">
              <label>{LABEL_CONSTANTS.ACCOUNT_OWNER}:</label>
              <div className="date-input">
                {account?.accountId} - {account?.name}
              </div>
            </div>
            <div className="form-row">
              <label>{ACCOUNT_CONSTANTS.PERMANENT_DISABLE_MESSAGE}</label>
              <div className="radio-row">
                <input
                  type="radio"
                  id="permanent-yes"
                  value="true"
                  checked={permanentlyDisable}
                  onChange={() => setPermanentlyDisable(true)}
                />
                <div>
                  <label htmlFor="permanent-yes">{LABEL_CONSTANTS.YES}</label>
                </div>
                <input
                  type="radio"
                  id="permanent-no"
                  value="false"
                  checked={!permanentlyDisable}
                  onChange={() => setPermanentlyDisable(false)}
                />
                <div>
                  <label htmlFor="permanent-no">{LABEL_CONSTANTS.NO}</label>
                </div>
              </div>
            </div>
            {!permanentlyDisable && (
              <div className="form-row">
                <div className="date-input">
                  <label>{LABEL_CONSTANTS.FROM}:</label>
                  <DateInput
                    value={startDate}
                    onChange={handleStartDateChange}
                    isClearable
                    showMonthYearPicker
                    minDate={new Date()}
                    maxDate={null}
                  />
                </div>
                <div className="date-input">
                  <label>{LABEL_CONSTANTS.TO}:</label>
                  <DateInput
                    value={endDate}
                    onChange={handleEndDateChange}
                    isClearable
                    showMonthYearPicker
                    minDate={startDate}
                    maxDate={null}
                    disabled={!startDate}
                  />
                </div>
              </div>
            )}
            {startDate && endDate && startDate > endDate && (
              <div className="validated-input-message-error">
                <AlertSVG />
                {VALIDATION_ALERT_CONSTANTS.SELECT_VALID_FROM_AND_TO_MONTH}
              </div>
            )}
            <div className="form-row expanded-text-area">
              <label htmlFor="notes">{LABEL_CONSTANTS.NOTES}:</label>
              <textarea
                name="notes"
                id="notes"
                placeholder="Notes"
                onChange={event => setNotes(event.target.value)}
                rows={4}
                cols={50}
                value={notes}
              />
            </div>
          </div>
          <div className="footer">
            <button className="button blue inverted small" onClick={handleClose}>
              {BUTTON_CONSTANTS.CANCEL}
            </button>
            <button
              className="small green button"
              disabled={!permanentlyDisable && (!startDate || !endDate || startDate > endDate)}
              onClick={handleConfirm}>
              {BUTTON_CONSTANTS.DISABLE}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BillingDisableModal;
