import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { Loader } from '../../components';
import BackButton from '../../components/back-button/BackButton';
import AnimalInfo from '../../components/animal/AnimalInfo';
import SelectSpecimens, { SelectedSpecimenQuantity } from '../../components/specimen-tables/SelectSpecimens';
import FilterDropdown, { FilterOption } from '../../components/custom-input/FilterDropdown';
import { Animal } from '../../types/interfaces';
import { TransactionTypeEnum } from '../../types/enums';
import { getAccountAnimals } from '../../api/animalsApi';
import { addSelectedSpecimenToCart } from '../../api/transactionCartApi';
import { showToast } from '../../services/toast.service';
import { fetchTransactionCartItems } from '../../services/transactionCart.service';
import { toastMessages } from '../../constants/errorMessages';
import { INVENTORY_PATHS, ROUTE_PATHS } from '../../constants/routePaths';
import {
  ANIMAL_CONSTANTS,
  BUTTON_CONSTANTS,
  INVENTORY_MANAGEMENT_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
} from '../../constants/common';

const InventoryWithdraw = (): JSX.Element => {
  const navigate = useNavigate();
  const { animalId: paramAnimalId } = useParams();

  const { activeAccount, user } = useAppSelector(state => state);
  const cartItems = useAppSelector(state => state.transactionCart.transactionCartItems);

  const [animalId, setAnimalId] = useState<string | undefined>(paramAnimalId?.toString() ?? paramAnimalId ?? '');
  const [selectedQuantities, setSelectedQuantities] = useState<{ valid: boolean; value: SelectedSpecimenQuantity[] }>({
    valid: false,
    value: [],
  });
  const [validate, setValidate] = useState<boolean>(false);
  const [specimenAddingToCart, setSpecimenAddingToCart] = useState<boolean>(true);
  const [selectedAnimal, setSelectedAnimal] = useState<FilterOption<Animal>>();
  const [animalOptions, setAnimalOptions] = useState<FilterOption<Animal>[]>([]);

  useEffect(() => {
    selectedQuantities.value.forEach(inventoryLot => {
      if (inventoryLot.selected && inventoryLot.quantity > inventoryLot.availableQuantity) {
        showToast.error(toastMessages.CANT_WITHDRAW_MORE_THAN_AVAIL_QTY);
      }
    });
  }, [selectedQuantities]);

  useEffect(() => {
    if (selectedAnimal?.value?.animalId) {
      setAnimalId(selectedAnimal?.value?.animalId?.toString());
    }
  }, [selectedAnimal]);

  useEffect(() => {
    const createFilterOptions = (animals: Animal[]) => {
      return animals.map((animal: Animal): FilterOption<Animal> => ({ name: `${animal.code}-${animal.name}`, value: animal }));
    };

    const getOwnedAnimals = async () => {
      setAnimalOptions([]);
      if (activeAccount?.accountId) {
        try {
          const { data } = await getAccountAnimals(activeAccount.accountId as number, {
            include: 'Breed.Species, Specimens, Owners',
          });

          const animalOptions = createFilterOptions(data as Animal[]);
          const animals = (data as Animal[]).find(animal => animal.animalId === Number(animalId));
          animals && setSelectedAnimal({ name: `${animals?.code}-${animals?.name}`, value: animals });
          setAnimalOptions(animalOptions);
        } catch {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    };
    getOwnedAnimals();
  }, [activeAccount]);

  const isQuantityValid = () => {
    if (selectedQuantities.value.every(inventoryLot => inventoryLot.quantity === 0)) {
      showToast.error(toastMessages.INVALID_ZERO_QUANTITY);
      return false;
    } else if (selectedQuantities.valid) {
      return true;
    } else {
      return false;
    }
  };

  const isValid = () => {
    return activeAccount.accountId && animalId != undefined;
  };

  const isNextStepValid = () => {
    if (selectedQuantities.valid || (cartItems.length > 0 && !animalId)) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddMoreSpecimen = () => {
    if (!specimenAddingToCart) return;
    setSpecimenAddingToCart(false);
    setValidate(true);

    if (isValid() && isQuantityValid()) {
      addSpecimenToCart();
    } else {
      setSpecimenAddingToCart(true);
    }
  };

  const handleNextStep = async () => {
    if (!specimenAddingToCart) return;

    setSpecimenAddingToCart(false);

    if (isValid() && isQuantityValid()) {
      try {
        await addSpecimenToCart();
        navigate(ROUTE_PATHS.APP_TRANSACTION_CART_WITHDRAW + activeAccount?.accountId);
      } catch {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    } else {
      setSpecimenAddingToCart(true);
      if (cartItems.length > 0 && !animalId) {
        navigate(ROUTE_PATHS.APP_TRANSACTION_CART_WITHDRAW + activeAccount?.accountId);
      }
    }
  };

  const addSpecimenToCart = async () => {
    try {
      const selectedSpecimens = {
        userId: user.userId,
        accountId: activeAccount?.accountId!,
        animalId: Number(animalId),
        specimenCartItems: selectedQuantities.value.map(specimen => {
          return {
            specimenId: specimen?.specimenId,
            transactionType: TransactionTypeEnum.Withdraw,
            quantity: specimen?.quantity,
          };
        }),
      };
      await addSelectedSpecimenToCart(selectedSpecimens);
      setSelectedAnimal(undefined);
      setAnimalId(undefined);
      fetchTransactionCartItems(user.userId, activeAccount.accountId);
      showToast.success(toastMessages.ADD_REQUEST_TO_CART_SUCCESS);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setSpecimenAddingToCart(true);
    }
  };

  const onAnimalChange = (animalId: number) => {
    navigate(ROUTE_PATHS.APP_INVENTORY + '/' + animalId + '/' + INVENTORY_PATHS.WITHDRAW);
  };

  return (
    <div className="inventory-withdraw">
      <BackButton />
      <div className="inventory-action card">
        <h1 className="withdraw-inventory-title">{PAGE_HEADER_CONSTANTS.WITHDRAW_INVENTORY}</h1>
        <p className="withdraw-inventory-guide">{INVENTORY_MANAGEMENT_CONSTANTS.WITHDRAW_INVENTORY_HEADER}</p>
        <br />

        <div className="form-row">
          <label>{ANIMAL_CONSTANTS.ANIMAL}:</label>
          <div className="input-container">
            <FilterDropdown
              options={animalOptions}
              value={selectedAnimal ?? { name: '', value: undefined }}
              disabled={animalOptions.length === 0}
              onChange={e => {
                setSelectedAnimal(e);
                onAnimalChange(e?.value?.animalId);
              }}
            />
          </div>
        </div>

        <AnimalInfo animalId={animalId} showQuantityAvailable />
        <br />

        {!specimenAddingToCart && <Loader loaderSize={'small'} />}

        <h2>{LABEL_CONSTANTS.SELECT_ITEM_QUANTITY}</h2>
        <SelectSpecimens animalId={+animalId!} accountId={activeAccount.accountId} onChange={e => setSelectedQuantities(e)} />
        <br />

        <div className="flex-right withdraw-submit-section">
          <div className="withdraw-submit-guide">
            <p className="withdraw-inventory-guide">{INVENTORY_MANAGEMENT_CONSTANTS.BULK_ORDER_ADD_MORE_FOOTER}</p>
            <p className="withdraw-inventory-guide">{INVENTORY_MANAGEMENT_CONSTANTS.BULK_ORDER_NEXT_STEP_FOOTER}</p>
          </div>
          <div className="buttons">
            <button
              className={selectedQuantities.valid ? 'button green' : 'button green disabled'}
              onClick={() => handleAddMoreSpecimen()}>
              {BUTTON_CONSTANTS.SAVE_AND_ADD_MORE}
            </button>
            <button
              className={isNextStepValid() ? 'button green small' : 'button green small disabled'}
              onClick={() => handleNextStep()}>
              {BUTTON_CONSTANTS.NEXT_STEP}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryWithdraw;
