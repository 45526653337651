import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Loader, ItemStorageLocations, BackButton } from '../../components';
import { AlertSVG } from '../../components/svgs';
import { SelectedSpecimenQuantity } from '../../components/specimen-tables/SelectSpecimens';
import TransactionLabelField from '../../components/transaction/TransactionLabelField';
import { dateFormatMMDDYYYY } from '../../utils/commonUtils';
import { TransactionTypeEnum } from '../../types/enums';
import { DiscardComplete, InventoryTransaction } from '../../types/interfaces';
import { getInventoryTransactionById } from '../../api/inventoryTransactionsApi';
import { completeDiscard } from '../../api/inventoryApi';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import {
  ANIMAL_CONSTANTS,
  BUTTON_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TRANSACTION_CONSTANTS,
} from '../../constants/common';
import './transactionManagement.scss';

const TransactionDiscard = () => {
  const { state: locationState } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [transaction, setTransaction] = useState<InventoryTransaction>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [quantities, setQuantities] = useState<SelectedSpecimenQuantity[]>([
    { specimenId: 0, availableQuantity: 0, quantity: 0, selected: false, selectedCanisterId: 0, specimenLocationId: 0 },
  ]);
  const [completingDiscard, setCompletingDiscard] = useState<boolean>(true);

  useEffect(() => {
    const getTransactionById = async () => {
      setIsLoading(true);
      try {
        const { data } = await getInventoryTransactionById(+id!, {
          include: 'Account, Specimen.Animal,Specimen.SpecimenLocations.StorageCanister.StorageTank.StorageSite',
        });
        data.changeQuantity = Math.abs(data.changeQuantity!);
        setTransaction(data);
        setNotes(data.notes ?? '');
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setIsLoading(false);
      }
    };

    getTransactionById();
  }, [id]);

  const onSubmit = async () => {
    if (!completingDiscard) return;
    setCompletingDiscard(false);

    if (!isFormDisabled(true)) {
      const mappedDiscardSpecimens: DiscardComplete[] = quantities
        .filter(item => item.selected === true && item.quantity > 0)
        .map(item => {
          return {
            specimenId: item.specimenId,
            quantity: item.quantity,
            selectedCanisterId: item.selectedCanisterId,
          };
        });
      setIsLoading(true);
      try {
        if (!transaction?.inventoryTransactionId) {
          throw new Error(LABEL_CONSTANTS.TRANSACTION_ID_NOT_FOUND);
        }
        await completeDiscard(transaction?.inventoryTransactionId, mappedDiscardSpecimens, notes);
        showToast.success(toastMessages.DISCARD_SUCCESSFUL);
        navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT, {
          state: { filterPreset: locationState.transactionManagementFilterPreset, filter: locationState.filter },
        });
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setIsLoading(false);
        setCompletingDiscard(true);
      }
    } else {
      setCompletingDiscard(true);
    }
  };

  const isFormDisabled = (showToasts?: boolean) => {
    if (Number(transaction?.changeQuantity) !== quantities.filter(q => q.selected).reduce((a, b) => a + Number(b.quantity), 0)) {
      showToasts && showToast.error(toastMessages.TRANSACTION_DISCARD_MISMATCH);
      return true;
    }
    if (quantities.some(item => item.quantity > item.availableQuantity)) {
      showToasts && showToast.error(toastMessages.INVALID_DISCARD_QUANTITY);
      return true;
    }
    if (quantities.some(item => item.selected && item.quantity === 0)) {
      showToasts && showToast.error(toastMessages.INVALID_ZERO_QUANTITY);
      return true;
    }
    return false;
  };

  return (
    <div>
      {locationState && locationState.transactionManagementFilterPreset && (
        <BackButton
          onClick={() =>
            navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT, {
              state: { filterPreset: locationState.transactionManagementFilterPreset, filter: locationState.filter },
            })
          }
        />
      )}
      <div className="inventory-action card inventory-discard">
        {isLoading && <Loader loaderSize={'medium'} pageLoader />}
        <h1>{PAGE_HEADER_CONSTANTS.COMPLETE_DISCARD}</h1>
        <h4>{TRANSACTION_CONSTANTS.TRANSACTION_INFORMATION}</h4>
        <hr />

        <TransactionLabelField label={`${TRANSACTION_CONSTANTS.TRANSACTION_ID}:`} value={id} />
        <TransactionLabelField
          label={`${LABEL_CONSTANTS.ACCOUNT_OWNER}:`}
          value={transaction?.account?.accountId + '-' + transaction?.account?.name}
        />
        <TransactionLabelField label={`${ANIMAL_CONSTANTS.ANIMAL_NAME}:`} value={transaction?.specimen?.animal?.name} />
        <TransactionLabelField
          label={`${LABEL_CONSTANTS.INVENTORY_LOT_NUMBER}:`}
          value={
            transaction?.specimen?.freezeDate &&
            `${dateFormatMMDDYYYY(transaction?.specimen?.freezeDate)} - ${transaction?.specimen?.specimenId} `
          }
        />
        <TransactionLabelField label={`${LABEL_CONSTANTS.QUANTITY_TO_DISCARD}:`} value={transaction?.changeQuantity} />
        <TransactionLabelField
          label={`${TRANSACTION_CONSTANTS.TRANSACTION_DATE}:`}
          value={dateFormatMMDDYYYY(transaction?.createdDatetime)}
        />

        <div className="form-row">
          <label htmlFor="transaction-notes">{LABEL_CONSTANTS.NOTES}:</label>
          <div className="input-container">
            <textarea id="transaction-notes" onChange={e => setNotes(e.target.value)} value={notes} placeholder="Notes" />
          </div>
        </div>

        {!completingDiscard && <Loader loaderSize="small" simple />}
        <br />
        <h4>{LABEL_CONSTANTS.ITEM_STORAGE_LOCATIONS}</h4>
        <hr />

        {transaction?.specimen && (
          <ItemStorageLocations
            transactionType={TransactionTypeEnum.Discard}
            setQuantities={setQuantities}
            specimen={transaction?.specimen}
            quantities={quantities}
          />
        )}

        {quantities.some(q => q.selected) &&
          Number(transaction?.changeQuantity) !==
            quantities.filter(q => q.selected).reduce((a, b) => a + Number(b.quantity), 0) && (
            <div className="validated-input-message-error margin-top">
              <AlertSVG />
              Total quantity selected must equal transaction quantity {Number(transaction?.changeQuantity)}
            </div>
          )}

        <div className="flex-right margin-top">
          <button
            type="submit"
            className={isFormDisabled() ? 'button green small disabled' : 'button green small'}
            onClick={onSubmit}>
            {BUTTON_CONSTANTS.COMPLETE}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransactionDiscard;
