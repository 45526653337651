import { FC } from 'react';
import DatePicker from 'react-datepicker';

type DateInputProps = {
  value: Date | undefined;
  onChange: (e: Date) => void;
  maxDate?: Date | null;
  minDate?: Date;
  isClearable?: boolean;
  disabled?: boolean;
  showMonthYearPicker?: boolean;
};

const DateInput: FC<DateInputProps> = ({
  value,
  onChange,
  maxDate = new Date(),
  minDate,
  isClearable: clearButton = false,
  disabled = false,
  showMonthYearPicker = false,
}: DateInputProps) => {
  return (
    <div className="date-input">
      <DatePicker
        placeholderText={showMonthYearPicker ? 'Select Month' : 'MM/DD/YYYY'}
        selected={value}
        onChange={(date: Date) => onChange(date)}
        maxDate={maxDate}
        minDate={minDate}
        showYearDropdown
        showMonthDropdown
        isClearable={clearButton}
        clearButtonClassName="date-input-clear-button"
        disabled={disabled}
        showMonthYearPicker={showMonthYearPicker}
        dateFormat={showMonthYearPicker ? 'MMMM yyyy' : 'MM/dd/yyyy'}
      />
    </div>
  );
};

export default DateInput;
