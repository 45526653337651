import { AxiosResponse } from 'axios';
import { ACCOUNT_API_ENDPOINTS } from '../constants/apiEndpoints';
import { appContextDelete, appContextPost, appContextPut, appContextGet } from './httpCommon/appAxios.service';
import { Account, ActiveAccount } from '../types/interfaces/account.interfaces';
import { ModifyUserInterface } from '../types/interfaces/user.interfaces';
import { PagedResponse, StandardParams } from '../types/interfaces/apiParams.interfaces';
import { AccountRoles } from '../types/enums';

export const getAccountById = (id: number, params?: { include: string }): Promise<AxiosResponse<ActiveAccount, any>> => {
  return appContextGet(ACCOUNT_API_ENDPOINTS.ACCOUNTS + id, params);
};

export const getAccountByName = (name: string): Promise<AxiosResponse<{}, any>> => {
  return appContextGet(ACCOUNT_API_ENDPOINTS.ACCOUNTS + name);
};

export const getAccounts = (
  accountIds?: number[],
  params?: StandardParams,
): Promise<AxiosResponse<Account[] | PagedResponse<Account>, any>> => {
  const queryString = accountIds && accountIds.length > 0 ? '?accountIds=' + accountIds.join('&accountIds=') : '';

  return appContextGet(ACCOUNT_API_ENDPOINTS.ACCOUNTS + queryString, params);
};

export const createAccount = (account: Account): Promise<AxiosResponse<{ accountId: number; userId: number }, any>> => {
  return appContextPost(ACCOUNT_API_ENDPOINTS.ACCOUNTS, account);
};

export const addUnknownUserToAccount = (userInfo: ModifyUserInterface): Promise<AxiosResponse<{}, any>> => {
  return appContextPost(ACCOUNT_API_ENDPOINTS.ACCOUNTS + userInfo.accountId + ACCOUNT_API_ENDPOINTS.ACCOUNT_USER, {
    username: userInfo.username,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    contactPhone: userInfo.contactPhone,
    roles: [{ name: userInfo.roles }],
  });
};

export const resendAccountInvite = (accountId: number, userId: number): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(
    ACCOUNT_API_ENDPOINTS.ACCOUNTS + accountId + ACCOUNT_API_ENDPOINTS.ACCOUNT_USER + userId + ACCOUNT_API_ENDPOINTS.INVITE,
    {},
  );
};

export const addUserToAccountByUsername = (
  username: string,
  accountRoles: AccountRoles,
  accountId: number | string,
): Promise<AxiosResponse<{}, any>> => {
  return appContextPost(
    ACCOUNT_API_ENDPOINTS.ACCOUNTS + accountId + ACCOUNT_API_ENDPOINTS.ACCOUNT_USER + encodeURI(username),
    [accountRoles],
    { template: 'AccountAccess' },
  );
};

export const addUserToAccountById = (accountId: string, userId: string, roles: string[]): Promise<AxiosResponse<{}, any>> => {
  return appContextPost(ACCOUNT_API_ENDPOINTS.ACCOUNTS + accountId + ACCOUNT_API_ENDPOINTS.ACCOUNT_USER + userId, roles);
};

export const removeUserFromAccount = (accountId: number, userId: number): Promise<AxiosResponse<{}, any>> => {
  return appContextDelete(ACCOUNT_API_ENDPOINTS.ACCOUNTS + accountId + ACCOUNT_API_ENDPOINTS.ACCOUNT_USER + userId);
};

export const updateUserInAccount = (accountId: number, userId: number, roles: any): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(ACCOUNT_API_ENDPOINTS.ACCOUNTS + accountId + ACCOUNT_API_ENDPOINTS.ACCOUNT_USER + userId, roles);
};

export const updateAccountSettings = (accountId: number, account: {}): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(ACCOUNT_API_ENDPOINTS.ACCOUNTS + accountId, account);
};

export const deactivateAccountById = (accountId: number): Promise<AxiosResponse<{}, any>> => {
  return appContextDelete(ACCOUNT_API_ENDPOINTS.ACCOUNTS + accountId);
};

export const lockOrUnlockAccount = (accountId: number): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(ACCOUNT_API_ENDPOINTS.ACCOUNTS + accountId + ACCOUNT_API_ENDPOINTS.LOCK_UNLOCK_ACCOUNT, {});
};

export const accountBillingEnableorDisable = (accountId: number, account: Partial<Account>): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(ACCOUNT_API_ENDPOINTS.ACCOUNTS + accountId + ACCOUNT_API_ENDPOINTS.TOGGLE_ACCOUNT_BILLING_STATUS, account);
};

export const getBillingDisabledAccountsCount = (): Promise<AxiosResponse<number>> => {
  return appContextGet(ACCOUNT_API_ENDPOINTS.ACCOUNTS + ACCOUNT_API_ENDPOINTS.DISABLED_BILLING_ACCOUNTS_COUNT);
};