import LabeledValue from '../labeled-value/LabeledValue';
import { Account } from '../../types/interfaces/account.interfaces';
import { LABEL_CONSTANTS } from '../../constants/common';
import './billingAccountInfo.scss';

const BillingAccountInfo = ({ account }: { account: Account }) => {
  return (
    <>
      <div className="card max-width">
        <div className="billing-account-info">
          <div className="body">
            <LabeledValue label={LABEL_CONSTANTS.ACCOUNT_NAME} value={account?.name} />
            <LabeledValue label={LABEL_CONSTANTS.ACCOUNT_ID} value={String(account?.accountId)} />
            <LabeledValue
              label={LABEL_CONSTANTS.POINT_OF_CONTACT}
              value={account != undefined ? account?.contactFirstName + ' ' + account?.contactLastName : ''}
            />
            <LabeledValue label={LABEL_CONSTANTS.CONTACT_EMAIL} value={account?.contactEmail} />
            <LabeledValue label={LABEL_CONSTANTS.OUTSTANDING_BALANCE} value={String(account?.outstandingBalanceCents)} />
            <LabeledValue
              label={LABEL_CONSTANTS.BILLING_STATUS}
              value={account?.billingOffStartMonth === null ? 'Enabled' : 'Disabled'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingAccountInfo;
