import { useEffect, useState } from 'react';
import { AccountSearch } from '../../components';
import BillingAccountInfo from '../../components/billing-management/BillingAccountInfo';
import BillingEnableModal from '../../components/billing-management/BillingEnableModal';
import BillingDisableModal from '../../components/billing-management/BillingDisableModal';
import PagedTable from '../../components/sorted-table/PagedTable';
import ActionMenu, { ActionButton } from '../../components/action-buttons/ActionButtons';
import { showToast } from '../../services/toast.service';
import {
  accountBillingEnableorDisable,
  getAccountById,
  getAccounts,
  getBillingDisabledAccountsCount,
} from '../../api/accountApi';
import { Account } from '../../types/interfaces';
import {
  TABLE_HEADER_CONSTANTS,
  BUTTON_CONSTANTS,
  LABEL_CONSTANTS,
  ACCOUNT_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
} from '../../constants/common';
import { toastMessages } from '../../constants/errorMessages';
import './billingManagement.scss';

const BillingManagement = () => {
  const [searchedAccount, setSearchedAccount] = useState<Account>();
  const [seletedAccount, setSelectedAccount] = useState<Account>();
  const [isAccountFromSearch, setIsAccountFromSearch] = useState<boolean>(false);
  const [billingDisabledAccountsCount, setBillingDisabledAccountsCount] = useState<number>();
  const [isOpenBillingEnableModal, setIsOpenBillingEnableModal] = useState<boolean>(false);
  const [isOpenBillingDisableModal, setIsOpenBillingDisableModal] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [billingDisabledAccountsData, setBillingDisabledAccountsData] = useState<Account[]>([]);

  const handleActionButtons = (account: Account) => {
    const enable: ActionButton = {
      name: `${BUTTON_CONSTANTS.ENABLE}`,
      action: () => handleAction(account, BUTTON_CONSTANTS.ENABLE),
    };
    const edit: ActionButton = { name: `${BUTTON_CONSTANTS.EDIT}`, action: () => handleAction(account, BUTTON_CONSTANTS.EDIT) };

    return [enable, edit];
  };

  const handleAction = (account: Account, action: string) => {
    if (account) {
      setSelectedAccount(account);
      action === BUTTON_CONSTANTS.ENABLE ? setIsOpenBillingEnableModal(true) : setIsOpenBillingDisableModal(true);
    }
  };

  const toggleEnableDisableButton = (billingOffStartMonth: Date | null | undefined) => {
    if (billingOffStartMonth !== null) {
      setIsOpenBillingEnableModal(true);
    } else {
      setIsOpenBillingDisableModal(true);
    }
  };

  const fetchBillingUpdatedAccount = async (accountId: number) => {
    if (searchedAccount?.accountId === accountId) {
      try {
        const { data: updatedAccount } = await getAccountById(accountId);
        setSearchedAccount(updatedAccount);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
    setRefresh(refresh => refresh + 1);
  };

  const handleAccountBilling =
    (action: typeof LABEL_CONSTANTS.ENABLE | typeof LABEL_CONSTANTS.DISABLE) =>
    async (data?: { startDate?: Date; endDate?: Date | null; notes?: string }) => {
      action === LABEL_CONSTANTS.ENABLE ? setIsOpenBillingEnableModal(false) : setIsOpenBillingDisableModal(false);

      if (seletedAccount?.accountId) {
        try {
          const payload = {
            billingOffStartMonth: action === LABEL_CONSTANTS.ENABLE ? null : data?.startDate || null,
            billingOffEndMonth: action === LABEL_CONSTANTS.ENABLE ? null : data?.endDate || null,
            notes: data?.notes || null,
          };
          await accountBillingEnableorDisable(+seletedAccount.accountId, payload);
          await fetchBillingUpdatedAccount(seletedAccount.accountId);
          showToast.info(
            action === LABEL_CONSTANTS.ENABLE
              ? toastMessages.ACCOUNT_BILLING_ENABLE + ' for ' + seletedAccount.name
              : toastMessages.ACCOUNT_BILLING_DISABLE + ' for ' + seletedAccount.name,
          );
        } catch (error: any) {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    };

  const fetchBillingDisabledAccountDetails = async () => {
    try {
      const response = await getAccounts(undefined, { filter: 'billingOffStartMonth neq NULL' });
      setBillingDisabledAccountsData(Array.isArray(response.data) ? response.data : []);
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    if (innerWidth <= 767) {
      fetchBillingDisabledAccountDetails();
    }
  }, [refresh]);

  const fetchBillingDisabledAccountsCount = async () => {
    try {
      const response = await getBillingDisabledAccountsCount();
      setBillingDisabledAccountsCount(response.data);
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      setBillingDisabledAccountsCount(0);
    }
  };

  useEffect(() => {
    fetchBillingDisabledAccountsCount();
  }, [refresh]);

  return (
    <>
      <h3 className="tab-header">{PAGE_HEADER_CONSTANTS.ADMIN_BILLING_MANAGEMENT}</h3>
      <div className="search-row">
        <label>{ACCOUNT_CONSTANTS.SEARCH_ACCOUNT}:</label>
        <div className="input-container">
          <AccountSearch
            onChange={(account: Account | undefined) => {
              setSearchedAccount(account);
              setIsAccountFromSearch(true);
            }}
          />
        </div>
      </div>
      {searchedAccount?.accountId && isAccountFromSearch && (
        <>
          <div className="account-info-text">{ACCOUNT_CONSTANTS.ACCOUNT_INFO}:</div>
          <div className="billing-account-information">
            <BillingAccountInfo account={searchedAccount} />
          </div>
          <div className="billing-container">
            <label>{ACCOUNT_CONSTANTS.BILLING_ENABLE_DISABLE_MESSAGE}</label>
            <button
              className="small green button"
              onClick={() => {
                setSelectedAccount(searchedAccount);
                toggleEnableDisableButton(searchedAccount.billingOffStartMonth);
              }}>
              {searchedAccount.billingOffStartMonth === null ? BUTTON_CONSTANTS.DISABLE : BUTTON_CONSTANTS.ENABLE}
            </button>
          </div>
        </>
      )}
      <hr className="divider" />
      <div className="disabled-accounts-count">
        <label>
          {ACCOUNT_CONSTANTS.BILLING_DISABLED_ACCOUNTS_COUNT} {billingDisabledAccountsCount}
        </label>
      </div>
      <div className="desk-billing-management">
        <PagedTable
          headers={[
            { displayName: `${TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER}` },
            { displayName: `${TABLE_HEADER_CONSTANTS.START_MONTH}` },
            { displayName: `${TABLE_HEADER_CONSTANTS.END_MONTH}` },
            { displayName: `${TABLE_HEADER_CONSTANTS.PERMANENTLY_DISABLED}` },
            { displayName: `${TABLE_HEADER_CONSTANTS.ACTION}` },
          ]}
          getData={params => getAccounts(undefined, { ...params, filter: 'billingOffStartMonth neq NULL' })}
          pageSize={8}
          minHeight={140}
          height={325}
          refresh={refresh}
          buildRow={t => {
            return [
              t.name,
              t.billingOffStartMonth
                ? new Date(t.billingOffStartMonth).toLocaleString('en-US', { month: 'long', year: 'numeric' })
                : '--',
              t.billingOffEndMonth
                ? new Date(t.billingOffEndMonth).toLocaleString('en-US', { month: 'long', year: 'numeric' })
                : '--',
              !t.billingOffEndMonth ? 'Yes' : 'No',
              <ActionMenu actionButtons={handleActionButtons(t)} />,
            ];
          }}
        />
      </div>

      {/** Mobile view */}
      <div className="xs-billing-management">
        {billingDisabledAccountsData.length === 0 ? (
          <div className="result-item">{`${LABEL_CONSTANTS.NO_RESULTS_FOUND}.`}</div>
        ) : (
          billingDisabledAccountsData &&
          billingDisabledAccountsData.map((account, index) => {
            return (
              <div className="card max-width" key={'account-' + account?.accountId + index}>
                <div className="billing-management-card">
                  <div className="billing-management-content">
                    <div className="billing-info">
                      <div className="billing-content">
                        <label>{`${TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER} : `}</label>
                        <label>{`${account?.name}`}</label>
                      </div>
                      <div className="billing-content">
                        <label>{`${TABLE_HEADER_CONSTANTS.START_MONTH} : `}</label>
                        <label>{`${
                          account?.billingOffStartMonth ? new Date(account.billingOffStartMonth).toLocaleDateString() : '--'
                        }`}</label>
                      </div>
                    </div>
                    <div className="billing-info">
                      <div className="billing-content">
                        <label>{`${TABLE_HEADER_CONSTANTS.END_MONTH} : `}</label>
                        <label>{`${
                          account?.billingOffEndMonth ? new Date(account.billingOffEndMonth).toLocaleDateString() : '--'
                        }`}</label>
                      </div>
                      <div className="billing-content">
                        <label>{`${TABLE_HEADER_CONSTANTS.PERMANENTLY_DISABLED} : `}</label>
                        <label>{!account?.billingOffEndMonth ? 'Yes' : 'No'}</label>
                      </div>
                    </div>
                  </div>
                  <div className="action-button">
                    <ActionMenu actionButtons={handleActionButtons(account)} />
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      {isOpenBillingEnableModal && (
        <BillingEnableModal
          isOpen={isOpenBillingEnableModal}
          account={seletedAccount}
          onClose={() => setIsOpenBillingEnableModal(false)}
          onConfirm={handleAccountBilling(LABEL_CONSTANTS.ENABLE)}
        />
      )}

      {isOpenBillingDisableModal && (
        <BillingDisableModal
          isOpen={isOpenBillingDisableModal}
          account={seletedAccount}
          onClose={() => setIsOpenBillingDisableModal(false)}
          onConfirm={handleAccountBilling(LABEL_CONSTANTS.DISABLE)}
        />
      )}
    </>
  );
};

export default BillingManagement;
