import { FC } from 'react';

interface LabeledValueProps {
  label?: string;
  value?: string;
}

const LabeledValue: FC<LabeledValueProps> = ({ label, value }) => {
  return (
    <>
      <div className={'labeled-value-info'}>
        <label>{label ?? ''}</label>
        <span>{value ?? ''}</span>
      </div>
      <hr className="divider" />
    </>
  );
};

export default LabeledValue;
