import { editAppRole, getUser, getUserOdata, getUserProfileImage } from '../api/userApi';
import { storeUserInfo, storeUserProfileImageBlobUrl } from '../redux/reducers/userSlice';
import { store } from '../redux/store';
import { AppRoles } from '../types/enums';
import { AccountUserInfo } from '../types/interfaces/user.interfaces';

export const requestUserPersonalInfo = async () => {
  try {
    const { userId } = store.getState().user;
    if (!userId) return;
    const { data: userData } = await getUser(userId, {
      include: 'Accounts.Account, Accounts.Roles, Addresses',
    });
    requestProfileImage(userData.profileImageId);
    userData.accounts = filterOutDeactivatedAccounts(userData.accounts);
    store.dispatch(storeUserInfo(userData));
  } catch (err: any) {
    throw err;
  }
};

export const requestProfileImage = async (profileImageId: string) => {
  if (profileImageId) {
    try {
      const profileImageUrl = await getUserProfileImage();
      store.dispatch(storeUserProfileImageBlobUrl(profileImageUrl));
    } catch (error) {
      throw error;
    }
  }
};

export const filterOutDeactivatedAccounts = (accounts: AccountUserInfo[]) => {
  return accounts.filter(account => account?.account?.enabled);
};

export const requestRsgUsers = () => {
  try {
    return getUserOdata({
      $select: 'profileImageId,firstName,lastName',
      include: 'Roles',
      $filter: 'Roles/$count gt 0',
    });
  } catch (error) {
    throw error;
  }
};

export const updateUserAppRole = (userId: number | string, role: AppRoles) => {
  try {
    return editAppRole(userId, [{ name: role }]);
  } catch (error) {
    throw error;
  }
};

export const removeUsersAppRole = (userId: number | string) => {
  try {
    return editAppRole(userId, []);
  } catch (error) {
    throw error;
  }
};
